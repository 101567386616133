import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import MainSection from "../components/MainSection"

export const query = graphql`
  query {
    imagesJson(pageName: { eq: "about" }) {
      background {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstSection {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      secondSection {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      thirdSection {
        childImageSharp {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const images = data.imagesJson

  return (
    <Layout>
      <SEO title="About Us" />
      <MainSection image={images.background.childImageSharp.fluid}>
        <p className="lead">Bob Keach &amp; Sons Carpentry</p>
        <h1>About Us</h1>
        <h2>Offering high-quality remodeling service since 1984.</h2>
      </MainSection>

      <section>
        <Container>
          <Row>
            <Col xs={12}>
              <h2 className="h3 section-title">
                We have the knowledge &amp; resources to make{" "}
                <span className="d-inline d-lg-block">
                  Your next project a huge success.
                </span>
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              xs={{ order: "first" }}
              md={12}
              lg={6}
              className="pb-5 pb-lg-0"
            >
              <Img
                className="card-img-top"
                fluid={images.firstSection.childImageSharp.fluid}
                alt="Image of whole Keach Carpentry Team"
              />
            </Col>
            <Col md={11} lg={6} className="col-mod-pl align-self-center">
              <h3 className="h4">
                Family-owned and operated company{" "}
                <br className="d-none d-lg-inline" />
                for over 35 years
              </h3>
              <div>
                <p>
                  We are a family-owned and operated company, specializing in a
                  frame to finish carpentry, home remodeling and renovation
                  including kitchen and bathroom remodeling, home additions,
                  decks, porches, and new construction.
                </p>
                <p>
                  We are a hands-on team of fully licensed and insured
                  professionals. We've been in business for over 35 years,
                  delivering high-quality construction services in the Easton
                  area.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="w-100 py-3"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={11} lg={6} className="col-mod-pl align-self-center">
              <h3 className="h4">Effective system and approach</h3>
              <div>
                <p>
                  Just as we are highly experienced general contractors, we’re
                  also dedicated to customer service. We know that home design
                  and construction can be stressful for a homeowner.
                </p>
                <p>
                  Over many years in business, we've developed an effective
                  system and approach, and we take pride in making the process
                  smooth, efficient, and transparent.
                </p>
              </div>
            </Col>
            <Col className="d-none d-lg-block" lg={6}>
              <Img
                className="card-img-top"
                fluid={images.secondSection.childImageSharp.fluid}
                alt="Image showing process of building a porch"
              />
            </Col>
          </Row>
        </Container>
        <div className="w-100 py-3"></div>
        <Container>
          <Row className="justify-content-center">
            <Col className="d-none d-lg-block" lg={6}>
              <Img
                className="card-img-top"
                fluid={images.thirdSection.childImageSharp.fluid}
                alt="home addition example from drone"
              />
            </Col>
            <Col md={11} lg={6} className="col-mod-pl align-self-center">
              <h3 className="h4">High-end work</h3>
              <div>
                <p>
                  We love high-end work such as custom additions where we can
                  get creative and bring the homeowners dreams to reality. But
                  we are not above replacing a window or building a deck.
                </p>
                <p>
                  We take on all jobs, great and small, and we put true
                  workmanship into every detail of what we do.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default AboutPage
