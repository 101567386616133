import React from "react"
import BackgroundImage from "gatsby-background-image"

import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import "./MainSection.css"

const MainSection = props => {
  const image = props.image

  return (
    <BackgroundImage id="main" Tag="section" fluid={image}>
      {!props.overlay && <div className="overlay"></div>}
      <Container>
        <Row className="main-text">
          <Col xs={12} sm={9} xl={12}>
            {props.children}
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
}

export default MainSection
